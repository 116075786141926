import { TContent } from "../../Models/User/@types";
import { useStoreActions } from "../../Stores";

export const usePdfDialog = () => {
    const { setPdfDialogProps } = useStoreActions(({ Dialogs: { setPdfDialogProps } }) => ({ setPdfDialogProps }));
    const onClose = () => {
        setPdfDialogProps({ content: undefined, open: false });
    };
    const showDialog = (content: TContent) => {
        setPdfDialogProps({ content: content, open: true, onClose: onClose })
    };
    return {
        showDialog
    }
}