import { request } from "../../Utils";

class Content {
  static saveContent = async (id: string, userId: string) => {
    const res = await request<any[]>({
      url: `contents/${id}/create-favourite/${userId}`,
      method: "POST",
    });
    return res;
  };
  static removeSaveContent = async (favouriteId: string) => {
    const res = await request<any>({
      url: `favourites/${favouriteId}/remove-favourite`,
      method: "DELETE",
    });
    return !!res.count;
  };
}
export default Content;
