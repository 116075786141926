import * as Yup from "yup";
import Cookies from "universal-cookie";

const cookies = new Cookies();

let emailSchema = Yup.object({
  email: Yup.string().email("Invalid Email").required("Email is required"),
});
let utilities = {
  toggleItemFromList: (
    list: any[] = [],
    item: any,
    key: string = "id",
    comparisonFunction?: (currentItem: any, item: any) => boolean
  ) => {
    let updatedList: any[] = [...list];
    let index = list.findIndex((i) =>
      comparisonFunction ? comparisonFunction(i, item) : i[key] === item[key]
    );
    index === -1 ? updatedList.push(item) : updatedList.splice(index, 1);
    return updatedList;
  },

  getTransformedImageUrl: (url: string, width: number, height: number) => {
    const str = `mithya/tr:w-${width},h-${height}/`;
    return url.replace("mithya/", str);
  },
  ValidateEmail: (mail: string) => emailSchema.validate({ email: mail }),
  //(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)),
  isListSimilar: (list1: Array<any>, list2: Array<any>) => {
    if (list1.length !== list2.length) return false;
    list1.forEach((elem) => {
      let index = list2.findIndex((i) => i.id === elem.id);
      if (index === -1) return false;
    });
    return true;
  },
  updateItemList: (
    list: Array<any>,
    item: any,
    action: ItemListUpdateAction
  ): typeof list => {
    list = list || [];
    let newList = list.slice();
    let itemIndex;
    if (action === "UPDATE") {
      itemIndex = newList.findIndex((listItem) => item.id === listItem.id);
      if (itemIndex !== -1) newList.splice(itemIndex, 1, item);
      return newList;
    } else if (action === "ADD") {
      newList.unshift(item);
      return newList;
    } else if (action === "DELETE") {
      return newList.filter((listItem) => item.id !== listItem.id);
    } else if (action === "PUT") {
      itemIndex = newList.findIndex((listItem) => item.id === listItem.id);
      if (itemIndex !== -1) newList.splice(itemIndex, 1, item);
      else {
        newList.push(item);
      }
      return newList;
    }
    return newList;
  },
  is_touch_device4() {
    var prefixes = " -webkit- -moz- -o- -ms- ".split(" ");
    var mq = function (query: any) {
      return window.matchMedia(query).matches;
    };
    if (
      "ontouchstart" in window ||
      //@ts-ignore
      (window.DocumentTouch && document instanceof DocumentTouch)
    ) {
      return true;
    }
    var query = ["(", prefixes.join("touch-enabled),("), "heartz", ")"].join(
      ""
    );
    return mq(query);
  },
  clearItemFromCookies: (key: string) => cookies.remove(key, { path: "/" }),
  addItemInCookies: (key: string, value: any) =>
    cookies.set(key, value, { path: "/", domain: "*", sameSite: "none" }),
  generateRandomString: (len: number) => {
    var p = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    return [...Array(len)].reduce(
      (a) => a + p[~~(Math.random() * p.length)],
      ""
    );
  }, // https://stackoverflow.com/a/44678459/10032950
};
export default utilities;

export type ItemListUpdateAction = "ADD" | "DELETE" | "UPDATE" | "PUT";