import { Button, ButtonProps } from '@material-ui/core';
import clsx from 'clsx';
import React, { FC } from 'react';
import { mediaStyles } from './PlayButton';

export interface ReadButtonProps extends ButtonProps {
    label?: string | JSX.Element
    primary?: boolean
}

const ReadButton: FC<ReadButtonProps> = (props) => {
    const { label = 'Read', className, primary = false, ...buttonProps } = props;
    const globalClasses = mediaStyles();
    return (
        <Button color='primary' variant='contained' className={clsx(primary ? globalClasses.primary : globalClasses.secondary, className,)} {...buttonProps}>{label}</Button>
    )
}


export default ReadButton