import { Dialog, DialogProps, Theme, Slide } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import React from 'react';
import DialogToolbar from './DialogToolbar';

export interface MainDialogProps extends Omit<DialogProps, 'onClose' | 'onSubmit'> {
    onClose?: () => void
    heading: string
}

class Transition extends React.Component {
    render() {
        return (
            <Slide direction="down" {...this.props} />
        )
    }
}


const MainDialog: React.FC<MainDialogProps> = (props) => {
    const { onClose = () => { }, heading, ...dialogProps } = props;
    const classes = useStyles()

    return (
        <Dialog onClose={() => onClose()} PaperProps={{ className: classes.paperRoot }} fullScreen {...dialogProps} TransitionComponent={Transition}>
            <DialogToolbar onClose={onClose} title={heading} />
            <div className={classes.content}>
                {props.children}
            </div>
        </Dialog>
    )
}

const useStyles = makeStyles((theme: Theme) => createStyles({
    paperRoot: {
        background: theme.palette.background.default,
    },
    content: {

    },

}))



// function Transition(props: any) {
//     return <Slide direction="down" {...props} />;
// }




export default MainDialog