import { TContent } from "../../Models/User/@types";
import { useStoreActions } from "../../Stores";

export const useMediaDialog = () => {
    const { setMediaDialogProps } = useStoreActions(({ Dialogs: { setMediaDialogProps } }) => ({ setMediaDialogProps }));


    const onClose = () => {
        setMediaDialogProps({ open: false, videos: [], });
    };
    const showDialog = (videos: TContent[]) => {
        setMediaDialogProps({ open: true, videos: videos, onClose: onClose });
    };
    return {
        showDialog
    };
}