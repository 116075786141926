import { FormConfig, IReactFormProps } from 'react-forms';
import * as Yup from 'yup';
export const validationSchema = Yup.object({
    email: Yup.string().email('Invalid Email').required('Email is required'),
    password: Yup.string().required('Password is required')
})
export const useLoginConfig = (passwordField: FormConfig) => {
    const loginConfig: IReactFormProps['config'] = [
        {
            valueKey: 'email',
            type: 'text',
            fieldProps: {
                label: 'Email',
                fullWidth: true
            }
        },
        passwordField
    ];
    const actionConfig: IReactFormProps['actionConfig'] = {
        submitButtonText: 'Submit',
        submitButtonLayout: 'fullWidth',
        submitButtonProps: {
            style: {
                marginBottom: 12
            }
        }
    };
    const validationSchema = Yup.object({
        email: Yup.string().email('Invalid Email').required('Email is required'),
        password: Yup.string().required('Password is required')
    })
    return {
        loginConfig,
        actionConfig,
        validationSchema
    }
}
export const loginConfig: IReactFormProps['config'] = [
    {
        valueKey: 'email',
        type: 'text',
        fieldProps: {
            label: 'Email',
            fullWidth: true
        }
    },
    //passwordField
];
export const actionConfig: IReactFormProps['actionConfig'] = {
    submitButtonText: 'Submit',
    submitButtonLayout: 'fullWidth',
}