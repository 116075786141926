import { Box, BoxProps } from '@material-ui/core';
import React, { FC, RefObject } from 'react';
import ReactPlayer from 'react-player';

export interface MediaPlayerProps extends BoxProps {
	url?: string | string[];
	onVideoEnd?: () => void;
	onTogglePlayPause?: (flag: boolean) => void;
	isPlaying?: boolean;
	playerRef?: RefObject<ReactPlayer>;
	onSeek?: (seconds: number)=> void
}

const MediaPlayer: FC<MediaPlayerProps> = (props) => {

	const { url, onVideoEnd, isPlaying = false, onTogglePlayPause, playerRef, onSeek,...boxProps } = props;

	return (
		<Box {...boxProps}>
			<ReactPlayer ref={playerRef}
				width="100%"
				height="100%"
				url={url || 'https://youtu.be/1ZYbU82GVz4?t=36'}
				playing={isPlaying}
				controls
				onEnded={onVideoEnd}
				onPause={() => { onTogglePlayPause?.(false); }}
				onPlay={() => { onTogglePlayPause?.(true); }}
				pip={false}
				config={{
					file: {
						attributes: {
							controlsList: 'nodownload',
							disablePictureInPicture: true
						}
					}
				}}
				onSeek={onSeek}
			/>
		</Box>
	);
};



export default MediaPlayer;