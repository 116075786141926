import { useContext } from "react";
import { ToastContext } from "../Utils/AppToast";
import { get } from "lodash";

const SUCCESS_TOAST_MESSAGE = "Success";
const ERROR_TOAST_MESSAGE = "Something went wrong";

const useToastMessage = () => {
  const { showToast } = useContext(ToastContext);

  const withToast = async (
    action: () => any,
    toastConfig: WithToastConfig = {}
  ) => {
    try {
      const res = await action();
      showToast(toastConfig.successToastMessage || SUCCESS_TOAST_MESSAGE, {
        variant: "success",
      });
      return res;
    } catch (error) {
      const msg =
        toastConfig.showApiErrorMsg === false
          ? toastConfig.errorToastMessage || ERROR_TOAST_MESSAGE
          : get(error, "response.data.error.message") || error.message;
      showToast(msg, { variant: "error" });
      throw error;
    }
  };

  return withToast;
};

export interface WithToastConfig {
  successToastMessage?: string;
  errorToastMessage?: string;
  showApiErrorMsg?: boolean;
}

export default useToastMessage;
