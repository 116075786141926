import React, { FC } from 'react';
import { ReactForm } from 'react-forms';
import { useResetPasswordConfig } from './config';
import { TResetPasswordData } from '../../Features/Auth/ResetPassword';

export interface ResetPasswordProps {
    loading?: boolean
    onSubmit: (values: TResetPasswordData) => void
}

const ResetPassword: FC<ResetPasswordProps> = (props) => {
    const { loading = false, onSubmit } = props;
    const { actionConfig, validationSchema, config } = useResetPasswordConfig();
    return (
        <ReactForm
            formId='reset-password'
            config={config}
            actionConfig={actionConfig}
            validationSchema={validationSchema}
            initialValues={{}}
            isInProgress={loading}
            onSubmit={onSubmit}
        />
    )
}


export default ResetPassword