import { Box, Paper } from '@material-ui/core';
import { createStyles, Theme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import React, { FC } from 'react';
import sherlock from '../../Assets/sherlock.jpg';
import PlayButton, { PauseButton } from '../../Components/PlayButton';
import ReadButton from '../../Components/ReadButton';
import ReadMore from '../../Components/ReadMore';
import Typo from '../../Components/Typo';
import { useMediaDialog } from '../../Dialogs/MediaDialog/useMediaDialog';
import { usePdfDialog } from '../../Dialogs/PdfDialog/usePdfDialog';
import { TContent } from '../../Models/User/@types';
import utilities from '../../Resources/Utils';

export interface ListCardProps {
    content: TContent
    variant?: 'small' | 'large'
    onClick?: () => void
    isActive?: boolean //states whter this is the active video from the playlist
    onTogglePlayPause?: (flag: boolean) => void //This method is fired when the current video is played or paused from the drawer
    isPlaying?: boolean // Flag to determine whether the video is playing or paused
}

const ListCard: FC<ListCardProps> = (props) => {
    const classes = useStyles();
    const { content, variant = 'large', isPlaying = false, isActive = false, onTogglePlayPause } = props;
    const { showDialog } = usePdfDialog();
    const { showDialog: showMediaDialog } = useMediaDialog();
    const caption = content.type === 'VIDEO' ? content.videoDuration : (content.documentPageCount ? `${content.documentPageCount} pages` : undefined);
    const handleItemClick = (action: 'video' | 'pdf') => {
        if (action === 'pdf') {
            showDialog(content)
        } else {
            if (props.onClick)
                props.onClick()
            else
                showMediaDialog([content])
        }
    }
    return (
        <Box my={variant === 'large' ? 1 : 2} className={clsx(isActive ? classes.border : '', classes.root)} width={variant === 'large' ? 800 : '100%'}>
            <Box zIndex={5} height='120px'>
                <img
                    src={utilities.getTransformedImageUrl(content._thumbnail?.url || '', 216, 120) || sherlock}
                    className={classes.img}
                    alt=''
                />
            </Box>
            <Paper elevation={2} className={clsx(classes.paper)}>
                <Box display='flex' width='100%'>

                    <Box flex={1} display='flex' justifyContent='space-between' alignItems='flex-start' px={1} py={1}>
                        <Box display='flex' flexDirection='column' alignItems='flex-start' width='80%'>
                            <Typo variant='subtitle1' weight='bold'>{content.name}</Typo>
                            <Typo variant='caption' weight='bold'>{(content.tags || []).join(', ')}</Typo>
                            <ReadMore text={content.description || ''} variant='caption' lines={2} limit={variant === 'large' ? 160 : 120}></ReadMore>
                        </Box>
                        <Box >
                            {content.type === 'VIDEO' ?
                                (isActive ? <>
                                    {isPlaying ? <PauseButton onClick={() => onTogglePlayPause?.(false)} /> : <PlayButton primary={false} onClick={() => onTogglePlayPause?.(true)} />}
                                </> : <PlayButton onClick={() => handleItemClick('video')} primary={false} />) :
                                <ReadButton onClick={() => handleItemClick('pdf')} primary={variant === 'small'}>Read</ReadButton>
                            }
                        </Box>
                    </Box>
                    <Box className={classes.nowPlaying}> <Typo variant='caption'>{caption}</Typo></Box>
                </Box>

            </Paper>
        </Box>
    )
}

const useStyles = makeStyles<Theme>((theme) => {
    return (createStyles({
        root: {
            display: "flex",
            alignItems: 'flex-start',
            [theme.breakpoints.down('sm')]: {
                width: '100%'
            },
            minHeight: 120,
        },
        border: {
            border: `2px solid ${theme.palette.primary.main}`
        },
        paper: {
            width: '100%',
            height: 'auto',
            minHeight: 120,
            [theme.breakpoints.down('sm')]: {
                width: '100%'
            },
            borderBottomLeftRadius: 0,
            borderTopLeftRadius: 0,
            position: 'relative',
            transition: '200ms linear'
        },
        img: {
            height: 120,
            transition: '300ms ease',
            boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)'
        },
        nowPlaying: {
            position: 'absolute',
            bottom: 10,
            right: 8
        }
    }))
})

export default ListCard