import { Box, Button, Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';
import 'read-more-less-react/dist/index.css';
import Typo from './Typo';

interface ReadMoreProps {
    lines?: number
    variant?: 'body1' | 'body2' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'subtitle1' | 'subtitle2' | 'caption'
    text: string
    onExpand?: () => void
    key?: string
    limit: number
}



const ReadMore: React.FC<ReadMoreProps> = (props) => {
    const { text = '', variant = 'body2', limit = 180 } = props;
    const [expanded, setExpanded] = useState(false)
    const classes = useStyles();


    const overflowing = text.length > limit;


    const getText = () => {
        let textToShow = text;
        if (overflowing) {
            if (!expanded) textToShow = textToShow.substr(0, limit) + '...';
        }
        return textToShow;
    }

    const BUTTON_TEXT = expanded ? 'Less' : 'More';

    return (
        <Box display='flex' flexDirection='column'>
            <Typo variant={variant}>{getText()}</Typo>
            {overflowing ? (
                <Box alignSelf='flex-end' position='absolute' bottom={8} right={60} >
                    <Button color="primary" onClick={() => setExpanded(!expanded)} className={classes.button}>{BUTTON_TEXT}</Button>
                </Box>
            ) : null}
        </Box>
    )

    // return (
    //     <div className={classes.root} style={style}>
    //         {isIE ? (
    //             <Typo variant={variant}>{text}</Typo>
    //         ) : (
    //                 <ReadMoreText
    //                     key={uniqueId()}
    //                     onAction={props.onExpand}
    //                     lines={lines}
    //                     text={text}
    //                 />
    //             )}
    //     </div>
    // )
}

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {

    },
    button: {
        paddingBottom: 0
    }

}))

export default ReadMore