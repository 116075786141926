import { useTheme } from '@material-ui/core/styles'
import React from 'react'
import Typo, { TypoProps } from './Typo'

interface ContrastTextProps extends Omit<TypoProps, 'color'> { }

const ContrastText: React.FC<ContrastTextProps> = (props) => {
    const { children, ...typoProps } = props;
    const theme = useTheme()
    return (
        <Typo {...typoProps} style={{ color: theme.palette.common.white }}>
            {children}
        </Typo>
    )
}


export default ContrastText