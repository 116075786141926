import {
  TUser,
  TUserLoginData,
  THospital,
} from "../../Models/User/@types";
import { Action, action, Thunk, thunk } from "easy-peasy";
import { Injections, TRootStore } from "..";
import utils from "../../Utils";

type ThunkAction<T = void, R = void> = Thunk<
  TUserState,
  T,
  Injections,
  TRootStore,
  Promise<R>
>;
export interface TUserState {
  user?: TUser;
  hospital?: THospital;
  setUser: Action<TUserState, TUser | undefined>;
  setHospital: Action<TUserState, THospital | undefined>;
  logout: Thunk<TUserState, void, Injections>;
  login: Thunk<TUserState, TUserLoginData, Injections>;
  meTask: Thunk<TUserState, {}, Injections>;
  //   fetchUser: Thunk<TUserState, TUser, Injections, TRootStore>;
  fetchUser: ThunkAction<TUser>;
  updateUser: ThunkAction<Partial<TUser>>;
}
const UserState: TUserState = {
  user: undefined,
  hospital: undefined,
  setUser: action((state, payload) => {
    state.user = payload;
  }),
  setHospital: action((state, payload) => {
    state.hospital = payload;
  }),

  meTask: thunk(async (actions, payload, { injections }) => {
    try {
      const user = await injections.UserModel.me();
      actions.setUser(user);
      await actions.fetchUser(user);
    } catch (err) {}
  }),
  logout: thunk(async (actions, payload, { injections, getStoreActions }) => {
    try {
      await injections.UserModel.logout();

      actions.setUser(undefined);
      actions.setHospital(undefined);
      utils.clearCookies();
    } catch (error) {
      throw error;
    }
  }),
  login: thunk(async (actions, payload, { injections }) => {
    try {
      const res = await injections.UserModel.login(payload);
      utils.setCookie("access_token", res.id);
      utils.setAuthHeader(res.id);
      const user = await injections.UserModel.me();
      actions.setUser(user);
      await actions.fetchUser(user);
      return user;
    } catch (error) {
      throw error;
    }
  }),
  fetchUser: thunk(async (actions, payload, { injections, getStoreState }) => {
    try {
      const userId = payload.id;
      const res = await injections.UserModel.fetchUser(userId, {
        include: { relation: "myList", scope: { include: "content" } },
      });
      actions.setUser({ ...payload, ...res });
      if (res.role === "HOSPITAL") {
        const hospital = await injections.UserModel.fetchHospital(
          res.hospitalId || "",
          { include: { relation: "myList", scope: { include: "content" } } }
        );
        getStoreState().List.myList = hospital.myList || [];
        actions.setHospital(hospital);
      } else {
        getStoreState().List.myList = res.myList || [];
        // getState().watchedList = mockData;
      }
    } catch (err) {
      throw err;
    }
  }),
  updateUser: thunk(async (_actions, payload, { injections, getState }) => {
    try {
      const userId = getState().user?.id;
      const user = await injections.UserModel.updateUser(userId!, payload);
      getState().user = user;
    } catch (err) {
      throw err;
    }
  }),
};
export default UserState;
