import { request } from "../../Utils"
import { TSummary } from "./@types"

export default class AppModel {

    static fetchSummary = async () => {
        return await request<TSummary>({
            url: '/AppModels/summary',
        });
    }
}