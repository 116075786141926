import { useStoreState, useStoreActions } from "../Stores";


const useVideoPlay = () => {
	const { myList } = useStoreState(({ List: { myList } }) => ({ myList }));
	const { setMyList } = useStoreActions(({ List: { setMyList } }) => ({ setMyList }));
	const markViewedInMyList = (contentId: string) => {
		const newList = myList?.map((itemList) => {
			itemList.content = itemList.content.map((item) => {
				if (item.id === contentId) {
					item.isViewed = true;
				}
				return item;
			});
			return itemList;
		}) || [];
		setMyList(newList);
	};
	return { markViewedInMyList };
};

export default useVideoPlay;