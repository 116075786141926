import { Action, action } from "easy-peasy";
import { MediaDialogProps } from "../../Dialogs/MediaDialog";
import { PdfDialogProps } from "../../Dialogs/PdfDialog";
import { LoginDialogProps } from "../../Dialogs/Auth/LoginDialog";

export interface TDialogState {
    pdfDialogProps: PdfDialogProps
    mediaDialogProps: MediaDialogProps
    loginDialogProps: LoginDialogProps
    setPdfDialogProps: Action<TDialogState, PdfDialogProps>
    setMediaDialogProps: Action<TDialogState, MediaDialogProps>
    setLoginDialogProps: Action<TDialogState, LoginDialogProps>
}
const DialogState: TDialogState = {
    pdfDialogProps: { open: false },
    setPdfDialogProps: action((state, payload) => {
        state.pdfDialogProps = payload;
    }),


    mediaDialogProps: { open: false, videos: [] },
    setMediaDialogProps: action((state, payload) => {
        state.mediaDialogProps = payload;
    }),

    loginDialogProps: { open: false },
    setLoginDialogProps: action((state, payload) => {
        state.loginDialogProps = payload;
    })

}
export default DialogState