import { Box, Paper, Button, Icon } from '@material-ui/core';
import { createStyles, Theme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import React, { FC } from 'react';
import { IReactFormProps, ReactForm } from 'react-forms';
import * as Yup from 'yup';
import Typo from '../../Components/Typo';
import IconButton from '../../Components/IconButton';
export interface ForgotPasswordProps {
    handleSubmit: (email: string) => Promise<void>
    onRetry?: () => void
    onClose?: () => void
}

const ForgotPassword: FC<ForgotPasswordProps> = (props) => {
    const classes = useStyles();
    const handleSubmit = async (data: { email: string }) => {
        await props.handleSubmit(data.email);
    }

    const config: IReactFormProps['config'] = [{
        type: 'text',
        valueKey: 'email',
        fieldProps: {
            label: 'Email',
            fullWidth: true,
        }
    }]
    return (
        <>

            <Paper className={classes.paper} elevation={2}>
                <IconButton onClick={props.onClose} className={classes.closeButton}>
                    <Icon>close</Icon>
                </IconButton>
                <Box>
                    <Typo weight='bold' variant='h6' >Enter your email</Typo>
                </Box>
                <Box>
                    <ReactForm
                        config={config}
                        formId='reset-password'
                        actionConfig={{
                            submitButtonLayout: 'fullWidth'
                        }}
                        onSubmit={handleSubmit}
                        validationSchema={Yup.object({
                            email: Yup.string().email('Please provide a valid Email').required('Email is required')
                        })}
                        initialValues={{}}
                        formSettings={{ verticalSpacing: 24 }}
                    />
                </Box>
                <Box mt={2}>
                    <Button variant='text' color='primary' className={classes.button} onClick={props.onRetry}>Retry Login</Button>
                </Box>
            </Paper>
        </>
    )
}

const useStyles = makeStyles<Theme>((theme) => {
    return (createStyles({
        paper: {
            width: 450,
            display: 'flex',
            flexDirection: 'column',
            margin: 'auto',
            padding: '20px',
            height: 'auto',
            boxSizing: 'border-box',
            position: 'relative',
            borderRadius: 12,
            transition: '300ms linear'
        },
        closeButton: {
            position: 'absolute',
            top: 20,
            right: 20,

        },
        container: {
            margin: '25px 0px',
            diplay: 'flex',
            flexDirection: 'column'
        }, button: {
            marginLeft: -8
        }
    }))
})

export default ForgotPassword