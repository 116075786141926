import { Fab, FabProps } from '@material-ui/core';
import clsx from 'clsx';
import React, { FC } from 'react';
import { useGlobalStyle } from '../Constants/globalStyle';

export interface IconButtonProps extends Partial<FabProps> {
    primary?: boolean
}

const IconButton: FC<IconButtonProps> = (props) => {
    const { className, primary = false, children, ...fabProps } = props;
    const globalClasses = useGlobalStyle();
    return (
        <Fab size='small' className={clsx(primary ? globalClasses.primaryButton : globalClasses.secondaryButton, className)} {...fabProps}>
            {children}
        </Fab>
    )
}


export default IconButton