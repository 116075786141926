
import useAsyncTask from "../../Hooks/useAsyncTask";
import useToastMessage from "../../Hooks/useToastMessage";
import UserModel from "../../Models/User";
import { TUserLoginData } from "../../Models/User/@types";
import { useStoreActions } from "../../Stores";

export const useLogin = () => {
    const { login } = useStoreActions(({ User: { login } }) => ({ login }));
    const { setLoginDialogProps } = useStoreActions(({ Dialogs: { setLoginDialogProps } }) => ({ setLoginDialogProps }))
    const onClose = () => {
        setLoginDialogProps({ open: false });
    }
    const showDialog = () => {
        setLoginDialogProps({ open: true, onClose: onClose });
    }
    const loginTask = useAsyncTask(login);
    const withToast = useToastMessage();
    const origin = window.location.origin;
    const handleForgotPassword = async (email: string) => {
        await withToast(async () => {
            await UserModel.requestResetPassword(`${origin}/reset-password`, email);
        }, {
            successToastMessage: 'Password reset information has been sent to your email.'
        })
    }
    const handleLogin = async (data: TUserLoginData) => {
        await withToast(async () => {
            await loginTask.run({ ...data, email: data.email.toLowerCase() });
        }, {
            successToastMessage: 'Login Successful!'
        })
    }
    return {
        handleLogin,
        loading: loginTask.status === 'PROCESSING',
        handleForgotPassword,
        showDialog
    }
}