import React, { FC } from 'react'
import { Box, AppBar, Toolbar } from '@material-ui/core'
import { createStyles, Theme } from '@material-ui/core/styles'
import { makeStyles } from '@material-ui/styles';
import LOGO from '../../Assets/logo.svg'
import UserMenu from './UserMenu';
import { useHistory } from 'react-router-dom';
export interface AppHeaderProps { }


export const HEADER_HEIGHT = 60;

const AppHeader: FC<AppHeaderProps> = (props) => {
    const classes = useStyles();
    const history = useHistory();
    const onLogoClick = () => {
        history.push('/listings')
    }
    return (
        <AppBar elevation={1} position="sticky" className={classes.appbar}>
            <Toolbar classes={{ gutters: classes.gutter }} className={classes.expanded}>
                <Box display='flex' width='100%' alignItems='center'>
                    <Box onClick={onLogoClick} style={{ cursor: 'pointer' }}><img src={LOGO} width="120px" alt='logo' /></Box>
                    <Box flex={1} />
                    <Box>
                        <UserMenu />
                    </Box>
                </Box>

            </Toolbar>
        </AppBar>
    )
}

const useStyles = makeStyles<Theme>((theme) => {
    return (createStyles({
        appbar: {
            backgroundColor: theme.palette.common.white,
            borderRadius: 0,
            transition: '200ms ease-in',
            '& img': {
                transition: '200ms ease-out'
            }
        },
        expanded: {
            height: HEADER_HEIGHT,
            transition: '200ms ease-in',
            '& img': {
                width: 150
            }
        },
        gutter: {
            [theme.breakpoints.down('sm')]: {
                paddingRight: 4
            }
        }
    }))
})

export default AppHeader