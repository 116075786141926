import React, { FC } from 'react';
import { ReactForm } from 'react-forms';
import { TUserLoginData } from '../../Models/User/@types';
import { useLoginConfig } from './config';
import usePasswordField from './usePasswordField';
export interface indexProps {
    onLogin: (data: TUserLoginData) => void
    loading: boolean
}

const Login: FC<indexProps> = (props) => {
    const { onLogin, loading } = props;
    const passwordField = usePasswordField('password', 'Password');
    const { actionConfig, loginConfig, validationSchema } = useLoginConfig(passwordField)

    return (
        <ReactForm
            formId='login'
            config={loginConfig}
            actionConfig={actionConfig}
            initialValues={{}}
            isInProgress={loading}
            validationSchema={validationSchema}
            onSubmit={onLogin}
            formSettings={{ verticalSpacing: 24 }}
        />
    )
}



export default Login