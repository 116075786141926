import { Thunk, Action, action, thunk } from "easy-peasy";
import { Injections } from "..";
import { TMyList, TContent } from "../../Models/User/@types";
import { Params } from "../../GlobalTypes/@types";
import utilities, { ItemListUpdateAction } from "../../Resources/Utils";

type ThunkAction<T = void, R = void> = Thunk<
	TListState,
	T,
	Injections,
	Promise<R>
>;
type StaticAction<T = void> = Action<TListState, T>;

interface TListState {
	watchedList?: TMyList;
	myList?: TMyList[];
	favouritedList?: TMyList;
	getWatchedList: ThunkAction<{ id: string; params: Params; }, TMyList>;
	getFavouritedList: ThunkAction<{ id: string; params: Params; }, TMyList>;
	saveItem: ThunkAction<{ item: TContent; userId: string; }, boolean>;
	unSaveItem: ThunkAction<
		{ favouriteId: string; removedContentId: string; },
		boolean
	>;
	setMyList: Action<TListState, TMyList[] | undefined>;
	setFavouriteList: StaticAction<TMyList>;
	updateWatchedList: StaticAction<{ action: ItemListUpdateAction, item: TContent; }>;
}

const ListState: TListState = {
	myList: [],
	watchedList: undefined,
	favouritedList: undefined,
	setMyList: action((state, payload) => {
		state.myList = payload;
	}),
	getWatchedList: thunk(
		async (_actions, { id, params }, { injections, getState }) => {
			try {
				const watchedList = await injections.UserModel.getWatchedList(
					id,
					params
				);
				getState().watchedList = watchedList;
			} catch (err) {
				throw err;
			}
		}
	),
	getFavouritedList: thunk(
		async (_actions, { id, params }, { injections, getState }) => {
			try {
				const favouritedList = await injections.UserModel.getFavouritedList(
					id,
					params
				);
				getState().favouritedList = favouritedList;
			} catch (err) {
				throw err;
			}
		}
	),
	saveItem: thunk(
		async (actions, { item, userId }, { injections, getState }) => {
			const res = await injections.ContentModel.saveContent(item.id, userId);
			const { favouritedList } = getState();
			if (res[1]) {
				actions.setFavouriteList({
					created: "",
					updated: "",
					id: "-~1!@#$4",
					name: "Saved",
					description: "",
					...favouritedList,
					contentIds: [...(favouritedList?.contentIds || []), item.id],
					content: [
						...(favouritedList?.content || []),
						{ ...item, favouriteId: res[0].id },
					],
				});
			}

			return res;
		}
	),
	unSaveItem: thunk(
		async (
			actions,
			{ favouriteId, removedContentId },
			{ injections, getState }
		) => {
			const res = await injections.ContentModel.removeSaveContent(favouriteId);
			const { favouritedList } = getState();
			if (res) {
				actions.setFavouriteList({
					created: "",
					updated: "",
					id: "-~1!@#$4",
					name: "Saved list",
					description: "",
					...favouritedList,
					contentIds: (favouritedList?.contentIds || []).filter(
						(contentId: string) => contentId !== removedContentId
					),
					content: utilities.updateItemList(
						favouritedList?.content || [],
						{ id: removedContentId },
						"DELETE"
					),
				});
			}
			return res;
		}
	),
	setFavouriteList: action((state, payload) => {
		state.favouritedList = payload;
	}),
	updateWatchedList: action((state, { action, item }) => {
		if (state.watchedList) {
			const newWatchedList = { ...state.watchedList };
			newWatchedList.contentIds = utilities.updateItemList(state.watchedList?.contentIds || [], item.id, action);
			newWatchedList.content = utilities.updateItemList(state.watchedList?.content || [], item, action);
			state.watchedList = newWatchedList;
		} else {
			const newWatchedList = { id: Math.random().toString(), name: 'Watched', } as TMyList;
			newWatchedList.contentIds = [item.id];
			newWatchedList.content = [item];
			state.watchedList = newWatchedList;
		}
	})
};

export default ListState;
