import { Action, action, Thunk, thunk } from "easy-peasy";
import { TSummary } from "../../Models/App/@types";
import { Injections } from "..";

export interface TState {
    initialized: boolean
    setInitialized: Action<TState, boolean>
    summary?: TSummary
    setSummary: Action<TState, TSummary>
    fetchSummary: Thunk<TState, {}, Injections>
}

const State: TState = {
    summary: undefined,
    setSummary: action((state, payload) => {
        state.summary = payload;
    }),
    fetchSummary: thunk(async (action, payload, { injections }) => {
        const summary = await injections.AppModel.fetchSummary();
        action.setSummary(summary);
    }),

    initialized: false,
    setInitialized: action((state, payload) => {
        state.initialized = payload;
    }),


};

export default State;