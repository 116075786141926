import axios, { AxiosError, AxiosRequestConfig } from 'axios';

export const request = async<T>(config: AxiosRequestConfig, log = true): Promise<T> => {
    if (!axios.defaults.baseURL) {
        throw new Error('Error: Base Url is not provided');
    }
    const resp = await axios.request<T>(config);

    return resp.data;
};
export type TCookieKey = 'UUID' | 'access_token' | 'infoPopover';
export const COOKIES_ACCESS_TOKEN: TCookieKey = 'access_token';
export const COOKIES_UUID: TCookieKey = 'UUID';
const utils = {
    setCookie: (key: TCookieKey, data: string) => localStorage.setItem(key, data),
    getCookie: (key: TCookieKey) => localStorage.getItem(key),
    clearCookies: () => {
        localStorage.removeItem(COOKIES_UUID)
        localStorage.removeItem(COOKIES_ACCESS_TOKEN)
    },
    setBaseAPI_URL: (url: string) => axios.defaults.baseURL = url,

    setHeader: (type = 'Content-Type', value = 'application/json') => axios.defaults.headers.post[type] = value,

    setAuthHeader: (access_token?: string) => axios.defaults.headers.common['Authorization'] = access_token,

    throwError: (error: AxiosError) => { console.log('Error', error.response); throw error }

}

export default utils;