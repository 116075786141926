import { Box, Button, ListItemText, Menu, MenuItem } from '@material-ui/core';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import useToastMessage from '../../Hooks/useToastMessage';
import { THospital, TUser } from '../../Models/User/@types';
import { useStoreActions, useStoreState } from '../../Stores';
export interface UserMenuProps {
    label?: string;
}

const UserMenu: FC<UserMenuProps> = (props) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const { user = {} as TUser, hospital = {} as THospital } = useStoreState(({ User: { hospital, user } }) => ({ user, hospital }));
    const handleClick = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);
    const { handleLogOut } = useUserMenu();
    const history = useHistory();
    const profileRedirect = () => history.push('/profile');
    const MENU_ITEMS = getMenuItems(handleLogOut, profileRedirect);

    const label = user.role === 'HOSPITAL' ? hospital.name || 'Menu' : `${user.firstName || 'Menu'} ${user.lastName || ''}`;
    return (
        <Box>
            <Button variant='text' onClick={handleClick}>
                {label}
                {Boolean(anchorEl) ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </Button>
            <Menu
                id="user-menu"
                anchorEl={anchorEl}
                // transformOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right', }}
                keepMounted

                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {
                    MENU_ITEMS.map((item, index) => {
                        return (<MenuItem key={index} onClick={() => {
                            item.action();
                            handleClose();
                        }}>
                            <ListItemText>{item.label}</ListItemText>
                        </MenuItem>);
                    })
                }
            </Menu>
        </Box>
    );
};
export const useUserMenu = () => {
    const withToast = useToastMessage();
    const { logout } = useStoreActions(({ User: { logout } }) => ({ logout }));
    const history = useHistory();
    const handleLogOut = () => {
        withToast(async () => {
            await logout();
            history.push('/');
        }, {
            successToastMessage: 'Logged out successfully',
            showApiErrorMsg: false
        });
    };
    return {
        handleLogOut
    };
};

export default UserMenu;

const getMenuItems = (handleLogOut: () => void, goToProfile: () => void): { label: string, action: () => any; }[] => {

    return [
        {
            label: 'Profile',
            action: goToProfile
        },
        {
            label: 'Log Out',
            action: handleLogOut
        }
    ];
};