import { InputAdornment } from '@material-ui/core';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import React, { useState } from 'react';
import { FormConfig } from 'react-forms';

const usePasswordField = (valueKey: string, label: string) => {
    const [passType, setPassType] = useState<'text' | 'password'>('password')
    const toggleType = () => {
        setPassType(type => type === 'password' ? 'text' : 'password');
    }

    return {
        type: passType,
        valueKey: valueKey,
        fieldProps: {
            label,
            fullWidth: true,
            InputProps: {
                endAdornment: <InputAdornment style={{ cursor: 'pointer' }} position="end" onClick={toggleType}>{
                    passType === 'password' ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />
                }</InputAdornment>
            }
        }
    } as FormConfig
}

export default usePasswordField;