import { LinearProgress } from '@material-ui/core';
import React, { FC, Suspense } from 'react';
import { Route, Switch, RouteProps, Redirect } from 'react-router-dom';
import lazyImport from '../lazy-import';
import Page404 from '../Screens/Page404';
import ResetPassword from '../Screens/Auth/ResetPassword';
import { useStoreState } from '../Stores';

const Landing = lazyImport('../Screens/Landing');
//const Login = lazyImport('../Screens/Auth/Login')
//const ResetPassword = lazyImport('../Screens/Auth/ResetPassword')
const Listings = lazyImport('../Screens/Listings');
const SingleList = lazyImport('../Screens/Listings/SingleList');
const Profile = lazyImport('../Screens/Profile');

const AppNavigation: FC = () => {
    return (
        <>
            <Suspense fallback={<LinearProgress />}>
                <Switch>
                    <Route path='/' exact component={Landing} />
                    <Route path='/reset-password' exact component={ResetPassword} />
                    <PrivateRoute path='/listings' exact component={Listings} />
                    <PrivateRoute path='/listings/:listId' exact component={SingleList} />
                    <PrivateRoute path='/profile' exact component={Profile} />
                    <Route path='/*' exact component={Page404} />
                </Switch>
            </Suspense>

        </>
    );
};
interface PrivateRouteProps extends RouteProps {

}
export const PrivateRoute: FC<PrivateRouteProps> = (props) => {
    const { user } = useStoreState(({ User: { user } }) => ({ user }));
    if (!user)
        return <Redirect to='/' />;
    return (
        <Route {...props} />
    );
};
export default AppNavigation;

