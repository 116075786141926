import { IReactFormProps } from 'react-forms';
import * as Yup from 'yup';
import usePasswordField from '../Login/usePasswordField';
export const useResetPasswordConfig = () => {

    const password = usePasswordField('password', 'Password');
    const confirmPassword = usePasswordField('confirmPassword', 'Confirm Password');
    const config: IReactFormProps['config'] = [
        password,
        confirmPassword
    ];
    const validationSchema = Yup.object({
        password: Yup.string().required('Password is required'),
        confirmPassword: Yup.string().oneOf([Yup.ref('password')], 'Password must match').required('Password is required')
    });
    const actionConfig: IReactFormProps['actionConfig'] = {
        submitButtonText: 'RESET',
        submitButtonLayout: 'fullWidth',
        submitButtonProps: {
            size: 'large'
        },
    };
    return {
        config,
        validationSchema,
        actionConfig,
    }
}