import { Box } from '@material-ui/core';
import { createStyles, Theme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import React, { FC } from 'react';
import { PDFObject } from 'react-pdfobject';
import MainDialog from '../../Components/MainDialog';
import { useGlobalStyle } from '../../Constants/globalStyle';
import { TContent } from '../../Models/User/@types';
import { HEADER_HEIGHT } from '../../Features/AppHeader';
export interface PdfDialogProps {
    content?: TContent
    open: boolean
    onClose?: () => void
}

const PdfDialog: FC<PdfDialogProps> = (props) => {
    const { content, open } = props;
    const classes = useStyles();
    const globalClasses = useGlobalStyle();
    if (!content)
        return <div />
    return (



        <MainDialog
            open={open}
            heading={content.name}
            onClose={props.onClose}

        >
            <Box className={clsx(globalClasses.gradientLightBackground, classes.root)}>
                {/* <Box className={classes.cover}> */}
                <Box className={classes.container}>
                    <PDFObject
                        url={content?._asset.url}
                        height={`calc(100vh - ${HEADER_HEIGHT}px)`}
                    />
                </Box>
                {/* </Box> */}
            </Box>
        </MainDialog>

    )
}

const useStyles = makeStyles<Theme>((theme) => {
    return (createStyles({
        root: {
            overflow: 'auto',
            display: 'flex',
            width: '100vw',
        },
        container: {
            width: 800,
            margin: 'auto',
            [theme.breakpoints.down('sm')]: {
                width: '100%',
            }
        },
        cover: {
            backgroundColor: '#fff',
            opacity: 0.2
        }
    }))
})

export default PdfDialog