import { createMuiTheme } from '@material-ui/core/styles';
import { THEME_PALETTE } from './themeValues';
const { others, ...palette } = THEME_PALETTE;

export default createMuiTheme({
    palette: {
        ...palette,
    },
    typography: {
        fontFamily: 'lato',
        fontWeightRegular: 400,
        fontWeightMedium: 400,
        fontWeightBold: 700,
        h1: {
            fontSize: 96
        },
        h2: {
            fontSize: 60
        },
        h3: {
            fontSize: 48
        },
        h4: {
            fontSize: 34,
            fontWeight: 700,
        },
        h5: {
            fontSize: 24,
            fontWeight: 700,
        },
        h6: {
            fontSize: 20,
            fontWeight: 700,
        },
        subtitle1: {
            fontSize: 16,
            fontWeight: 700,
        },
        subtitle2: {
            fontSize: 14,
            fontWeight: 700,
        },
        body1: {
            fontSize: 16
        },
        body2: {
            fontSize: 14
        },
        caption: {
            fontSize: 12
        },
        overline: {
            fontSize: 10
        },

    },
    overrides: {
        MuiPaper: {
            root: {
                borderRadius: 12
            }
        },
        MuiBackdrop: {
            root: {
                backgroundColor: 'rgba(0, 0, 0, 0.9)'
            }
        },
        MuiButton: {
            root: {
                textTransform: 'none'
            }
        },
        // MuiFab: {
        //     root: {
        //         boxShadow: '5px 5px 5px 5px'
        //     }
        // }
    }
})