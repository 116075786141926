import { Box, Button, Dialog, Icon, Paper } from '@material-ui/core';
import { createStyles, Theme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import React, { FC, useState } from 'react';
import IconButton from '../../Components/IconButton';
import Typo from '../../Components/Typo';
import { useGlobalStyle } from '../../Constants/globalStyle';
import LoginForm from '../../Forms/Login';
import { TUserLoginData } from '../../Models/User/@types';
import ForgotPassword from './ForgotPassword';
import { useLogin } from './useLogin';

export interface LoginDialogProps {
    open: boolean
    onClose?: () => void
}

const LoginDialog: FC<LoginDialogProps> = (props) => {
    const { open = false, onClose } = props;
    const classes = useStyles();
    const [step, setStep] = useState<1 | 2>(1);
    const globalClasses = useGlobalStyle();
    const { handleLogin: doLogin, loading, handleForgotPassword } = useLogin();
    const handleSubmit = async (email: string) => {
        await handleForgotPassword(email);
        onClose?.();
    }
    const handleLogin = async (data: TUserLoginData) => {
        await doLogin(data);
        onClose?.();
    }
    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullScreen={true}
            BackdropProps={{
                style: {
                    opacity: 0
                }
            }}
            PaperProps={{
                // style: {
                //     backgroundColor: 'transparent',
                //     boxShadow: 'none',
                //     position: 'fixed',
                // },
                className: globalClasses.gradientDarkBackground
            }}
        >
            <>

                {step === 1 ?
                    <Paper elevation={2} className={classes.paper}>
                        <IconButton onClick={props.onClose} className={classes.closeButton}>
                            <Icon>close</Icon>
                        </IconButton>
                        <Box mb={2}><Typo weight='bold' variant='h6' >LOGIN</Typo></Box>
                        <LoginForm onLogin={handleLogin} loading={loading} />
                        <Button variant='text' color='primary' className={classes.button} onClick={() => setStep(2)}>Reset Password</Button>

                    </Paper> :
                    <ForgotPassword handleSubmit={handleSubmit} onRetry={() => setStep(1)} onClose={props.onClose} />
                }
            </>
        </Dialog>
    )
}

const useStyles = makeStyles<Theme>((theme) => {
    return (createStyles({
        closeButton: {
            position: 'absolute',
            top: 20,
            right: 20,
        },
        paper: {
            width: 450,
            minHeight: 320,
            position: 'relative',
            boxSizing: 'border-box',
            padding: '30px 30px 15px',
            margin: 'auto',
            transition: '300ms linear',
            [theme.breakpoints.down('sm')]: {
                width: '100%',
            },
            borderRadius: 12
        },
        button: {
            marginLeft: -8
        }
    }))
})

export default LoginDialog