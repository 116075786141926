import App from "./App";
import _isEqual from "lodash/isEqual";
import { createStore, createTypedHooks, State } from "easy-peasy";
import UserModel from "../Models/User";
import AppModel from "../Models/App";
import ContentModel from "../Models/Content";
import User from "./User";
import Dialogs from "./Dialogs";
import List from "./List";
export type TRootStore = typeof RootStore;

export interface Injections {
  ContentModel: typeof ContentModel;
  UserModel: typeof UserModel;
  AppModel: typeof AppModel;
}
const RootStore = {
  App,
  User,
  Dialogs,
  List,
};

const typedHooks = createTypedHooks<TRootStore>();

export const useStoreActions = typedHooks.useStoreActions;
export const useStoreDispatch = typedHooks.useStoreDispatch;
export const useStoreState = <Result>(
  a: (state: State<TRootStore>) => Result,
  b?: any
) => typedHooks.useStoreState(a, b || _isEqual);

export default createStore(RootStore, {
  //Put your dependency injections here
  injections: {
    UserModel,
    AppModel,
    ContentModel,
  },
});
