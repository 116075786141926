import React, { FC, useEffect } from 'react';
import DialogsContainer from '../Features/DialogsContainer';
import AppNavigation from '../Routes/AppNavigation';
import AppDialogProvider from '../Utils/AppDialogProvider';
import AppToastProvider from '../Utils/AppToast';
import { useStoreState } from '../Stores';
import FullScreenLoader from '../Components/FullScreenLoader';
import { useAppInit } from '../Hooks/useAppInit';
import Boot from '../Boot';


const Layout: FC = () => {
	const { initialized } = useStoreState(({ App: { initialized } }) => ({ initialized }));
	const { appInit } = useAppInit();
	console.count('app');

	useEffect(() => {
		Boot().then(async () => {
			await appInit();
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	
	return (
		<div>
			{!initialized ? <FullScreenLoader /> : <AppNavigation />}
			<DialogsContainer />
		</div>
	);
};


const RootLayout: FC = () => {
	return (
		<>
			<AppToastProvider>
				<AppDialogProvider>
					<Layout />
				</AppDialogProvider>
			</AppToastProvider>
		</>
	);
};

export default RootLayout;

