import { Box, Drawer } from '@material-ui/core';
import { createStyles, Theme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import React, { FC } from 'react';
import DialogToolbar from '../../Components/MainDialog/DialogToolbar';
import { useGlobalStyle } from '../../Constants/globalStyle';
import { HEADER_HEIGHT } from '../../Features/AppHeader';
import ListCard from '../../Features/Listings/ListCard';
import { TContent } from '../../Models/User/@types';

export interface MediaDrawerProps {
    open: boolean
    title?: string
    videos: TContent[]
    currentIndex?: number
    onPlay?: (index: number) => void //This method is called when another video is selected to play.
    onClose?: () => void
    onTogglePlayPause?: (flag: boolean) => void //This method is fired when the current video is played or paused from the drawer
    isPlaying?: boolean //Flag to determine whether the video is playing or paused
}

const MediaDrawer: FC<MediaDrawerProps> = (props) => {
    const classes = useStyles();
    const globalStyle = useGlobalStyle();
    const { open, videos, currentIndex, title = 'All Videos', isPlaying, onTogglePlayPause } = props;
    return (
        <Drawer onClose={props.onClose} open={open} PaperProps={{ className: classes.paperRoot }} BackdropProps={{ style: { opacity: 0.3 } }} anchor='left'>
            <Box height='100%' width='100%' className={clsx(classes.wrapper)}>
                <DialogToolbar onClose={props.onClose} title={title} />
                <Box p={2} display="flex" className={clsx(classes.root, globalStyle.gradientLightBackground)} flexDirection="column" alignItems="flex-start">

                    <Box width="100%" my={2}>
                        {
                            videos.map((item, index) => {
                                return <ListCard
                                    content={item}
                                    key={index}
                                    variant='small'
                                    onClick={() => props.onPlay?.(index)}
                                    isActive={index === currentIndex}
                                    isPlaying={isPlaying}
                                    onTogglePlayPause={onTogglePlayPause}

                                />
                            })
                        }
                    </Box>
                </Box>
            </Box>
        </Drawer>
    )
}
const useStyles = makeStyles<Theme>((theme) => {
    return (createStyles({
        paperRoot: {
            backgroundColor: 'transparent rgba(0,0,0,0.2)',
            width: 570,
            borderRadius: 0,
            [theme.breakpoints.down('sm')]: {
                width: '100%'
            }
        },
        wrapper: {
            height: '100vh',
            overflow: 'auto'
        },
        root: {
            width: '100%',
            minHeight: `calc(100vh - ${HEADER_HEIGHT}px)`,
            height: 'auto',
            boxSizing: 'border-box',
            overflowY: 'auto'
        },
        title: {
            height: 64,
            padding: '0 8px',
            boxSizing: 'border-box',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            borderRadius: 0,
            backgroundColor: theme.palette.common.white
        }
    }))
})

export default MediaDrawer