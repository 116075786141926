import { Fab, FabProps } from '@material-ui/core';
import { createStyles, Theme } from '@material-ui/core/styles';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import PauseIcon from '@material-ui/icons/Pause';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import React, { FC } from 'react';
export interface PlayButtonProps extends FabProps {
    primary?: boolean
}

const PlayButton: FC<PlayButtonProps> = (props) => {
    const classes = mediaStyles();
    const { className, primary = false, ...fabProps } = props;
    return (
        <Fab size={'small'} className={clsx(primary ? classes.primary : classes.secondary, className)} {...fabProps}><PlayArrowIcon /> </Fab>
    )
}
export interface PauseButtonProps extends FabProps {
}
export const PauseButton: FC<PauseButtonProps> = (props) => {
    const classes = mediaStyles();
    const { className, ...fabProps } = props;
    return (
        <Fab size={'small'} className={clsx(classes.secondary, className)} {...fabProps}><PauseIcon /> </Fab>
    )
}
export const mediaStyles = makeStyles<Theme>((theme) => {
    return (createStyles({
        primary: {
            background: theme.palette.primary.main,
            color: 'white',
            transition: '300ms linear',
            boxShadow: 'none',
            '&:hover': {
                background: theme.palette.primary.main,
                color: 'white',
                boxShadow: '0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12)'
            }
        },
        secondary: {
            color: theme.palette.primary.main,
            background: 'white',
            border: '1px solid #808080',
            boxShadow: 'none',
            transition: '300ms linear',
            '&:hover': {
                background: theme.palette.primary.main,
                color: 'white',
                border: '1px solid ' + theme.palette.primary.main,
                boxShadow: '0 0 6px 2px rgba(241, 89, 42,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12)'
            }
        }
    }))
})

export default PlayButton