import React, { FC } from 'react';
import LoginDialog from '../Dialogs/Auth/LoginDialog';
import MediaDialog from '../Dialogs/MediaDialog';
import PdfDialog from '../Dialogs/PdfDialog';
import { useStoreState } from '../Stores';

export interface DialogsContainerProps { }

const DialogsContainer: FC<DialogsContainerProps> = (props) => {
    const { pdfDialogProps, mediaDialogProps, loginDialogProps } = useStoreState(({
        Dialogs: { pdfDialogProps, mediaDialogProps, loginDialogProps } }) => ({
            pdfDialogProps,
            loginDialogProps,
            mediaDialogProps
        }))
    return (
        <>
            {pdfDialogProps.open && <PdfDialog {...pdfDialogProps} />}
            {mediaDialogProps.open && <MediaDialog {...mediaDialogProps} />}
            {loginDialogProps.open && <LoginDialog {...loginDialogProps} />}
        </>
    )
}


export default DialogsContainer