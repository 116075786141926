import { TFilter } from "..";
import { request } from "../../Utils";
import { THospital, TUser, TUserLoginData } from "./@types";
import {
  parseToUser,
  parseToHospital,
  parseToSpecialListItem,
  specialListToMyList,
} from "./UserParsers";
import { Params } from "../../GlobalTypes/@types";
import { savedListId, watchedListId } from "../../Constants/constants";

export default class UserModel {
  static updateUser = async (id: string, data: Partial<TUser>) => {
    return parseToUser(
      await request<Record<string, any>>({
        url: `users/${id}`,
        method: "PATCH",
        data,
      })
    );
  };
  static me = async () =>
    parseToUser(
      await request<TUser>({
        url: "users/me",
      })
    );
  static login = (data: TUserLoginData) =>
    request<{ id: string; userId: string }>({
      url: "users/login",
      method: "post",
      data,
    });
  static logout = () =>
    request({
      url: "users/logout",
      method: "post",
    });
  static fetchUser = async (id: string, filter?: TFilter) => {
    return parseToUser(
      await request<TUser>({
        url: `users/${id}`,
        method: "GET",
        params: { filter },
      })
    );
  };
  static fetchHospital = async (id: string, filter?: TFilter) => {
    return parseToHospital(
      await request<THospital>({
        url: `users/getHospital`,
        method: "GET",
        params: { filter },
      })
    );
  };
  static requestResetPassword = async (redirectUrl: string, email: string) => {
    await request({
      url: "/users/forget-password",
      method: "POST",
      data: {
        email,
        redirectUrl,
      },
    });
  };
  static resetPassword = async (
    newPassword: string,
    headers?: Record<string, any>
  ) => {
    await request({
      url: "/users/reset-password",
      method: "POST",
      data: { newPassword },
      headers,
    });
  };
  static isEmailRegistered = async (email: string) => {
    return await request({
      url: "users/is-registered",
      method: "GET",
      params: { email },
    });
  };
  static getWatchedList = async (id: string, params: Params) => {
    const res = await request<Record<string, any>[]>({
      url: `users/${id}/views`,
      method: "GET",
      params,
    });
    return specialListToMyList(
      res.map(parseToSpecialListItem),
      "Watched",
      watchedListId
    );
  };
  static getFavouritedList = async (id: string, params: Params) => {
    const res = await request<Record<string, any>[]>({
      url: `users/${id}/favourites`,
      method: "GET",
      params,
    });
    return specialListToMyList(
      res.map(parseToSpecialListItem),
      "Saved",
      savedListId
    );
  };
}
