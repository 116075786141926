import { fade } from "@material-ui/core/styles";

export const THEME_PALETTE = {
  grey: {
    100: "#262626",
    200: "#4D4D4D",
    300: "#808080",
    400: "#D9D9D9",
    500: "#474747",
    600: "#707070",
    700: "#E5E8F2",
    800: "#363535",
    900: "#AFAFAF",
    A100: "#676767",
  },
  text: {
    primary: "#333333",
    secondary: "#333333",
    // primary: "#4D4D4D",
    // secondary: "#808080"
  },
  background: {
    default: "#F5F5F5",
    paper: "#fff",
  },
  common: {
    black: "#000",
    white: "#fff",
  },
  action: {
    active: "#000",
  },
  error: {
    main: "#B71840",
  },
  others: {
    activeGreen: "#38B468",
    gradient: `transparent linear-gradient(320deg, #0073E7 0%, #7426AD 100%) 0% 0% no-repeat padding-box`,
  },
  primary: {
    light: "#0073E6",
    main: "#F1592A",
    dark: "#01003C",
    contrastText: "#FFFFFF",
  },
  secondary: {
    light: fade("#1375BC", 0.1),
    main: "#1375BC",
    dark: "",
    contrastText: "#FFFFFF",
  },
  success: {
    main: "#4caf50",
  },
  warning: {
    main: "#ff9800",
  },
  info: {
    main: "#2196f3",
  },
};
