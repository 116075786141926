import React, { FC } from 'react'
import { Box, Paper } from '@material-ui/core'
import { createStyles, Theme } from '@material-ui/core/styles'
import { makeStyles } from '@material-ui/styles';
import Typo from '../../../Components/Typo';
import ResetPasswordForm from '../../../Forms/ResetPassword'
import qs from 'query-string'
import { useHistory, useLocation } from 'react-router-dom';
import UserModel from '../../../Models/User';
import useToastMessage from '../../../Hooks/useToastMessage';
export interface TResetPasswordData {
    password: string;
    confirmPassword: string
}
export interface ResetPasswordProps { }

const ResetPassword: FC<ResetPasswordProps> = (props) => {
    const classes = useStyles();
    const history = useHistory();
    const location = useLocation()
    const query = qs.parse(location.search)
    const withToast = useToastMessage();
    const onSubmit = async (values: TResetPasswordData) => {

        await withToast(async () => {
            if (query.token)
                await UserModel.resetPassword(values.password, { common: { Authorization: query.token } });
            else
                throw Error('')
        }, {
            successToastMessage: 'Password Reset Successful.',
            errorToastMessage: 'Invalid link , please try again.',
            showApiErrorMsg: !(query.token === undefined || query.token === undefined)
        })
        history.push('/');

    }
    return (
        <Paper elevation={2} className={classes.root} >
            <Box mb={2}>
                <Typo variant='subtitle1' weight='bold'>Reset Password</Typo>
            </Box>
            <ResetPasswordForm onSubmit={onSubmit} />
        </Paper>
    )
}

const useStyles = makeStyles<Theme>((theme) => {
    return (createStyles({
        root: {
            width: 450,
            height: 280,
            padding: 25,
            display: 'flex',
            boxSizing: 'border-box',
            flexDirection: 'column',
            margin: 'auto',
            borderRadius: 12,
            [theme.breakpoints.down('sm')]: {
                width: '100%',
                margin: 16
            }
        }
    }))
})

export default ResetPassword