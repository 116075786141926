import config from "../Config";
import io from "socket.io-client";
// import utilities from "../Resources/Utils";
import { COOKIES_ACCESS_TOKEN } from "../Utils";
import { TUser } from "../Models/User/@types";
import { useMemo } from "react";
import { useStoreState } from "../Stores";
import chalk from "chalk";

// export const SOCKET_CLIENT_ID_KEY = "socketClientId";
export const socket = io.connect(config.get("BASE_URL"), {
  autoConnect: false,
});
export const SOCKET_EVENT_KEYS = {
  JOIN: "join",
  VIDEO_PLAY: "playContent",
  VIDEO_PAUSE: "pauseContent",
  VIDEO_SEEK: "seekVideo",
};
let user: TUser | undefined = undefined;

export const useSocketListener = () => {
  const { JOIN } = SOCKET_EVENT_KEYS;
  const { user: appUser } = useStoreState(({ User: { user } }) => ({
    user,
  }));

  user = appUser;
  const path = useMemo(() => {
    return window.location.href;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.href]);

  const socketInit = () => {
    // disconnectSocket();
    try {
      //   socket.removeAllListeners();
      console.log("socket connection requested");
      socket.connect();

      socket.on("connect", () => {
        console.log("socket connection successful");
      });
    } catch (err) {
      console.error(err);
    }
    socket.emit(
      JOIN,
      {
        accessToken: localStorage.getItem(COOKIES_ACCESS_TOKEN),
      },
      (data: { clientId?: string }) => {
        // utilities.clearItemFromCookies(SOCKET_CLIENT_ID_KEY);
        // utilities.addItemInCookies(SOCKET_CLIENT_ID_KEY, data.clientId);
        console.log(JOIN, data);
      }
    );
  };
  const TrackCustomEvent = useMemo(() => {
    return {
      emit: (
        event: keyof typeof SOCKET_EVENT_KEYS,
        data: object,
        callback: (resp: any) => any = () => {}
      ) => {
        /**
         * @author Adnan Husain
         *
         *  A generalized socket function that emits the desired event on server.
         *
         * @param eventName : event you want to fire.
         * @param data : data you want to send.
         * @param callback : {optional} callback fired if there is any callback that is fired in response to the event from server.
         */
        const date = new Date();
        data = {
          ...data,
        };

        console.log(chalk.bgGreen("TRACK CUSTOM EVENT => "), {
          userId: user?.id,
          page: path,
          event: SOCKET_EVENT_KEYS[event],
          sentTime: date.toString(),
          data,
        });
        socket.emit(
          "newEvent",
          {
            name: SOCKET_EVENT_KEYS[event],
            page: path,
            sentTime: date,
            data,
            userId: user?.id,
          },
          callback
        );
      },
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.id, path]);
  return { socketInit, socket, TrackCustomEvent };
};
