import React, { FC } from 'react';
import { LinearProgress } from '@material-ui/core';
import { createStyles, Theme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';


export const FullScreenLoader: FC = () => {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <LinearProgress className={classes.loader} />
        </div>
    )
}

export default FullScreenLoader;

const useStyles = makeStyles<Theme>((theme) => createStyles({
    root: {
        width: '100%',
        display: 'flex',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center'
    },
    loader: {
        width: 300
    }
})
)