import { Box, Dialog, Icon } from '@material-ui/core';
import { createStyles, Theme } from '@material-ui/core/styles';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { makeStyles } from '@material-ui/styles';
import React, { FC, useEffect, useState, useRef, RefObject } from 'react';
import ContrastText from '../../Components/ContrastText';
import IconButton from '../../Components/IconButton';
import { HEADER_HEIGHT } from '../../Features/AppHeader';
import { TContent } from '../../Models/User/@types';
import MediaDrawer from './MediaDrawer';
import MediaPlayer from './MediaPlayer';
import { useSocketListener } from '../../Hooks/useSocketListener';
import ReactPlayer from 'react-player';
import utilities from '../../Resources/Utils';
import { useStoreActions } from '../../Stores';
import useVideoPlay from '../../Hooks/useVideoPlay';
export interface MediaDialogProps {
	title?: string;
	open: boolean;
	videos: TContent[];
	onClose?: () => void;
}

const MediaDialog: FC<MediaDialogProps> = (props) => {
	const { open, videos, title } = props;
	const classes = useStyles();
	const { updateWatchedList } = useStoreActions(({ List: { updateWatchedList } }) => ({ updateWatchedList }));
	const { markViewedInMyList } = useVideoPlay();
	const [currentVideo, setCurrentVideo] = useState<TContent>();
	const [showDrawer, setShowDrawer] = useState<boolean>(false);
	const [isPlaying, setIsPlaying] = useState<boolean>(false);
	const toggleShowDrawer = () => setShowDrawer(!showDrawer);
	const { TrackCustomEvent } = useSocketListener();
	const [sessionId, setSessionId] = useState<string>('');
	useEffect(() => {
		setSessionId(utilities.generateRandomString(40));
	}, []);

	const toggleIsPlaying = (flag: boolean) => {
		if (flag && currentVideo) {
			if (!currentVideo.isViewed) {
				updateWatchedList({ action: 'ADD', item: { ...currentVideo, isViewed: true } });
				markViewedInMyList(currentVideo.id);
			}
			TrackCustomEvent.emit("VIDEO_PLAY", { contentId: currentVideo?.id, playbackDuration: (playerRef as RefObject<ReactPlayer>)?.current?.getCurrentTime(), sessionId }, logResponse);
		}
		else if (currentVideo) {
			TrackCustomEvent.emit("VIDEO_PAUSE", { contentId: currentVideo?.id, playbackDuration: (playerRef as RefObject<ReactPlayer>)?.current?.getCurrentTime(), sessionId }, logResponse);
		}
		if (isPlaying === flag)
			return;
		setIsPlaying(flag);
	};
	useEffect(() => {
		if (videos.length > 0) {
			setCurrentVideo(videos[0]);
			toggleIsPlaying(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [videos]);

	const playerRef = useRef(null);

	const onVideoPlay = (index: number) => {
		let video = videos[index];
		// TrackCustomEvent.emit("VIDEO_PLAY", { contentId: video.id, playbackDuration: (playerRef as RefObject<ReactPlayer>)?.current?.getCurrentTime(), sessionId }, logResponse);
		if (video) {
			setCurrentVideo(video);
			toggleIsPlaying(true);
		}
	};
	const onVideoEnd = () => {
		if (currentVideo) {
			console.log("Video ended.");
			TrackCustomEvent.emit("VIDEO_PAUSE", { contentId: currentVideo.id, playbackDuration: (playerRef as RefObject<ReactPlayer>)?.current?.getCurrentTime(), sessionId });
			let index = videos.findIndex(i => currentVideo.id === i.id);
			if (index < videos.length - 1) {
				setCurrentVideo(videos[index + 1]);
				toggleIsPlaying(true);
			}// else {
			// setCurrentVideo(videos[0]);
			// toggleIsPlaying(true);
			// }
		}
	};

	const onVideoSeek = (seconds: number) => {
		TrackCustomEvent.emit('VIDEO_SEEK', { contentId: currentVideo?.id, playbackDuration: seconds, sessionId });
	};
	const handleClose = () => {
		if (currentVideo)
			TrackCustomEvent.emit("VIDEO_PAUSE", { contentId: currentVideo.id, playbackDuration: (playerRef as RefObject<ReactPlayer>)?.current?.getCurrentTime(), sessionId });
		props.onClose?.();
	};
	return (

		<Dialog
			open={open}
			onClose={handleClose}
			fullScreen={true}
			PaperProps={{ className: classes.paper }}
		>
			<Box width='100%' height='100%'>
				<Box className={classes.header}>
					<Box className={classes.titleContainer}>
						{videos.length > 1 && <IconButton onClick={toggleShowDrawer} className={classes.fileIcon} primary={true}> <FileCopyIcon /></IconButton>}
						<ContrastText variant='h6' weight='bold' style={{ marginLeft: 8 }}>{currentVideo?.name}</ContrastText>
					</Box>
					<IconButton onClick={handleClose}>
						<Icon>close</Icon>
					</IconButton>
				</Box>
				{/* <VideojsPlayer url={videos.map(i => i.url)} height='100%' width='100%' /> */}
				<MediaPlayer onSeek={onVideoSeek} playerRef={playerRef} url={currentVideo?._asset.url} height='100%' width='100%' onVideoEnd={onVideoEnd} isPlaying={isPlaying} onTogglePlayPause={toggleIsPlaying} />
				{showDrawer && <MediaDrawer open={showDrawer} title={title} videos={videos} onClose={toggleShowDrawer} onPlay={onVideoPlay} currentIndex={videos.findIndex(i => i.id === currentVideo?.id)} onTogglePlayPause={toggleIsPlaying} isPlaying={isPlaying} />}
			</Box>
		</Dialog>

	);
};

const useStyles = makeStyles<Theme>((theme) => {
	return (createStyles({
		paper: {
			background: 'transparent',
			boxShadow: 'none',
			[theme.breakpoints.down('sm')]: {
				height: '100%'
				// height: `calc(100vh - ${HEADER_HEIGHT_SMALL}px)`
			},

		},
		header: {
			position: 'absolute',
			top: 0,
			padding: '0 20px',
			boxSizing: 'border-box',
			height: HEADER_HEIGHT,
			display: 'flex',
			width: '100%',
			alignItems: 'center',
			justifyContent: 'space-between',
			zIndex: 100,
		},
		titleContainer: {
			display: 'flex',
			alignItems: 'center'
		},
		fileIcon: {
			marginRight: 12
		},
		closeButton: {
			boxShadow: 'none',
			'&:hover': {
				boxShadow: 'none',
			}
		}
	}));
});

export default MediaDialog;

const logResponse = (resp: any) => { console.log({ resp }); };