import { AppBar, Box, Icon, Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { HEADER_HEIGHT } from '../../Features/AppHeader';
import Typo from '../Typo';
import IconButton from '../IconButton';

interface DialogToolbarProps {
    onClose?: () => void
    title?: string
}

const DialogToolbar: React.FC<DialogToolbarProps> = (props) => {
    const { title = 'Main Dialog' } = props;
    const classes = useStyles()


    return (
        <AppBar elevation={1} position="sticky" className={classes.appbar}>
            <Box display='flex' alignItems="center" className={classes.toolbar}>
                <Typo variant='h6' weight='bold' color='textSecondary'>{title}</Typo>
                <Box flex={1} />
                <IconButton onClick={props.onClose}>
                    <Icon>close</Icon>
                </IconButton>
            </Box>
        </AppBar>
    )
}

const useStyles = makeStyles((theme: Theme) => createStyles({
    toolbar: {
        padding: '0px 24px',
        [theme.breakpoints.down('sm')]: {
            height: 64,
            padding: '0px 8px 0px 16px',
            '& img': {
                width: 120
            }
        },
        [theme.breakpoints.up('sm')]: {
            height: HEADER_HEIGHT,
        }
    },
    appbar: {
        background: 'white',
        borderRadius: 0
    },
    closeButton: {
        background: theme.palette.common.white
    }
}))

export default DialogToolbar