import { TUser, THospital, SpecialListItem, TMyList, TContent } from "./@types";

export const parseToUser = (obj: Record<string, any>): TUser => {
  return { ...obj } as TUser;
};

export const parseToHospital = (obj: Record<string, any>): THospital => {
  return { ...obj } as THospital;
};
export const parseToSpecialListItem = (
  obj: Record<string, any>
): SpecialListItem => {
  return { ...obj } as SpecialListItem;
};

export const specialListToMyList = (
  obj: SpecialListItem[],
  name: string, id:string
): TMyList => {
  return {
    name,
    content: obj.map((item) => item.subject || ({} as TContent)),
    id ,
    contentIds: obj.map((item) => item.id),
    created: Date.now().toString(),
    updated: Date.now().toString(),
    description: "",
  };
};
