import { Box } from '@material-ui/core';
import { createStyles, Theme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import React, { FC } from 'react';
import { useGlobalStyle } from '../../../Constants/globalStyle';
import ResetPasswordForm from '../../../Features/Auth/ResetPassword';
export interface ResetPasswordProps { }
const ResetPassword: FC<ResetPasswordProps> = (props) => {
    const classes = useStyles();
    const globalClasses = useGlobalStyle();
    return (
        <Box className={clsx(classes.root, globalClasses.gradientLightBackground)}>
            <ResetPasswordForm />
        </Box>
    )
}

const useStyles = makeStyles<Theme>((theme) => {
    return (createStyles({
        root: {
            // backgroundImage: `url(${bush})`,
            // backgroundRepeat: 'repeat',
            overflow: 'auto',
            height: '100vh',
            display: 'flex',
            width: '100%',
        },
        paper: {
            width: 450,
            height: 300,
            boxSizing: 'border-box',
            padding: '40px 30px',
            margin: '100px auto'
        }
    }))
})

export default ResetPassword