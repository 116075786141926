import { useStoreActions, useStoreState } from "../Stores";
import utils, { COOKIES_ACCESS_TOKEN } from "../Utils";
import { useSocketListener } from "./useSocketListener";
import { useEffect } from "react";

export const useAppInit = () => {
  const {
    meTask,
    setInitialized,
    fetchSummary,
    getWatchedList,
    getFavouritedList,
  } = useStoreActions(
    ({
      User: { meTask },
      List: { getWatchedList, getFavouritedList },
      App: { setInitialized, fetchSummary },
    }) => ({
      meTask,
      setInitialized,
      fetchSummary,
      getWatchedList,
      getFavouritedList,
    })
  );

  const { user } = useStoreState(({ User: { user } }) => ({ user }));
  const { socketInit, socket } = useSocketListener();
  const access_token = localStorage.getItem(COOKIES_ACCESS_TOKEN);
  const appInit = async () => {
    utils.setAuthHeader(access_token || "");
    await meTask({});
    if (!!user?.id) {
      await getWatchedList({
        id: user?.id || "",
        params: { filter: { include: { relation: "subject" } } },
      });
      await getFavouritedList({
        id: user?.id || "",
        params: { filter: { include: { relation: "subject" } } },
      });
    }
    await fetchSummary({});
    setInitialized(true);
  };
  useEffect(() => {
    if (user?.id) {
      console.count("request count");
      socketInit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.id]);
  console.log("connected:", socket.connected);
  return {
    appInit,
  };
};
