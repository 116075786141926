import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import bush from '../Assets/bush.png';
import { HEADER_HEIGHT } from '../Features/AppHeader';
export const useGlobalStyle = makeStyles((theme: Theme) => createStyles({
    background: {
        backgroundImage: `url(${bush})`,
        backgroundRepeat: 'repeat',
        height: `calc(100vh - ${HEADER_HEIGHT}px)`
    },
    tranparentBackground: {
        background: `transparent url(${bush}) 0% 0% no-repeat padding-box`,
        backgroundColor: 'rgba(255,255,255,0.6)',
        backgroundRepeat: 'repeat',
        height: `calc(100vh - ${HEADER_HEIGHT}px)`
    },
    gradientDarkBackground: {
        background: 'transparent linear-gradient(to bottom right, rgb(241, 89, 42,0.85) 0%, rgb(19, 117, 188,0.85) 100%) 0% 0% no-repeat padding-box'
    },
    gradientLightBackground: {
        background: 'transparent linear-gradient(to bottom right, rgb(241, 89, 42,0.15) 0%, rgb(19, 117, 188,0.15) 100%) 0% 0% no-repeat padding-box'
    },
    cover: {
        backgroundColor: 'rgba(255,255,255,0.6)',
    },
    primaryButton: {
        background: theme.palette.primary.main,
        color: 'white',
        transition: '300ms linear',
        boxShadow: 'none',
        '&:hover': {
            color: theme.palette.primary.main,
            background: 'white',
            boxShadow: '0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12)'
        }
    },
    secondaryButton: {
        color: theme.palette.primary.main,
        background: 'white',
        boxShadow: 'none',
        transition: '300ms linear',
        '&:hover': {
            background: theme.palette.primary.main,
            color: 'white',
            boxShadow: '0 0 6px 2px rgba(241, 89, 42,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12)'
        }
    }
}))
